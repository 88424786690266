@pullback-button-color: #8D39E2;
@pullback-button-bg-color: #DDCFEB;

.pullback-button {
  color: @white-color;
  background-color: @pullback-button-color;
  border-color: @pullback-button-color;

  &:hover,
  &:focus,
  &:active {
    color: @white-color;
    background-color: @pullback-button-color;
    border-color: @pullback-button-color;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    color: @pullback-button-color;
    background-color: @pullback-button-bg-color;
    border-color: @pullback-button-color;
  }
}

.pullback-status {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0;
  border: 2px solid @normal-color;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;

  &.approved {
    border-color: @approved-color;
    color: @approved-color;
  }

  &.rejected {
    border-color: @rejected-color;
    color: @rejected-color;
  }

  &.pending {
    color: #5f5f5f;
    background-color: #dadada;
    border-color: #c4c4c4;
  }
}

.pullback-request-card {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.pullback-committee-row {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
}
