@approved-color: #86C95C;
@rejected-color: #E23D3A;
@notAnswered-color: #ECB800;

.opinion-button {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 2px solid @normal-color;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;

  &.approved {
    border-color: @approved-color;
    color: @approved-color;
  }

  &.rejected {
    border-color: @rejected-color;
    color: @rejected-color;
  }

  &.notAnswered {
    border-color: @notAnswered-color;
    color: @notAnswered-color;
  }
}

.opinion-card {
  border: 1px solid @grey-4;
  margin-bottom: 1rem;

  &:last-child {
    margin: 0;
  }

  &-inner {
    background-color: #f6f6f6;
    margin-bottom: 1rem;
  }
}
