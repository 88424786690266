.down-button {
  position: absolute !important;
  top: 5px !important;
  z-index: 10 !important;
  right: 10px !important;
  margin-top: 10px !important;
  border-radius: 50px !important;
  background-color: #3da4e6 !important;
  opacity: 0.6 !important;
  color: #fff !important;
  padding: 0 8px !important;
  &:hover {
    opacity: 1 !important;
  }
}
@media screen and (max-width: 657px) {
}
