.ant-popover-inner {
  padding: 0.75rem;

  .ant-popover-buttons {
    margin: 0;

    .ant-btn {
      margin: 0;

      &:last-child {
        margin-left: 0.75rem;
      }
    }
  }
}
