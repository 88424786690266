.@{class-prefix}-word-break-all {
  word-break: break-all !important;
}

.@{class-prefix}-word-break-word {
  word-break: break-word !important;
}

.@{class-prefix}-word-break-keep-all {
  word-break: keep-all !important;
}
