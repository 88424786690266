.token-expired-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .token-expired-card {
    width: 500px;

    >div {
      margin: '24px 48px 24px 48px';
    }
  }

  .token-expired-title {
    margin-bottom: 48px !important;
  }
}
