textarea {
  &.@{class-prefix}-resize-none {
    resize: none !important;
  }

  &.@{class-prefix}-resize-both {
    resize: both !important;
  }

  &.@{class-prefix}-resize-vertical {
    resize: vertical !important;
  }

  &.@{class-prefix}-resize-horizontal {
    resize: horizontal !important;
  }
}
