.ant-upload-list-text {
  display: flex;
  flex-wrap: wrap;

  .ant-upload-list-item-card-actions-btn {
    margin-bottom: 0;
    .ant-btn-sm {
      width: 20px;
      height: 20px;
    }
  }
}
