.header-fixed {
  position: fixed;
  width: 100vw;
}

.header-content-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 72px;
  width: 100vw;
  padding-right: 32px;
}
