.timeline-point {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  padding: 2px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
