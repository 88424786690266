@import '~react-simple-keyboard/build/css/index.css';

.backdrop-keyboard {
  position: absolute;
  display: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 9999999999;

  .keyboard-button-wrapper {
    position: relative;
    width: inherit;
    height: inherit;

    .handle {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: move;
      flex: 1;
      width: 1.5rem;
      margin-right: 0.25rem;
    }
  }

  .keyboard-button {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    right: 0;
    pointer-events: all;
    margin: 0 25px 55px 0;
    padding: 0.75rem;
  }

  .keyboard-container {
    display: none;
    pointer-events: all;
    justify-content: center;
    align-items: stretch;
    border-radius: 10px 5px 5px 10px;
    width: 650px;

    &.active {
      display: flex;
    }

    * {
      opacity: 0.5;
      pointer-events: none;
      transition: all 0.7 ease;
    }

    &.keyboard-hover * {
      opacity: 1;
      pointer-events: all;
    }

    .react-simple-keyboard {
      width: 93%;
      border-radius: 5px 0 0 5px;

      .hg-rows {
        padding: 0 4px;
      }

      .hg-row {
        padding: 2px;
      }

      .kb-font-large {
        font-size: large;
      }

      .kb-font-larger {
        font-size: larger;
      }

      .kb-font-xlarge {
        font-size: x-large;
      }

      .kb-spacer {
        visibility: hidden;
        pointer-events: none;
      }
    }

    .caps-keyboard {
      width: 7%;
      min-height: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 8px 0;
      align-items: center;
      border-radius: 0px 5px 5px 0px;
      background-color: #E67742;
      cursor: move;

      & .close-icon {
        border: 1px solid #fff;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;

        path {
          fill: #fff;
        }

        &:hover {
          background-color: #fff;

          path {
            fill: #E67742;
          }
        }
      }

      & .caps-button {
        border-radius: 5px;
        padding: 5px 2px;
        cursor: pointer;
        border: 1px solid #fff;

        &:hover {
          background-color: rgba(255, 255, 255, 0.8);

          & .caps-icon {
            path {
              fill: #E67742;
              stroke: #E67742;
            }
          }
        }

        & .caps-icon {
          path {
            fill: #fff;
            stroke: #fff;
          }
        }

        &.active {
          background-color: #fff;

          & .caps-icon {
            path {
              fill: #E67742;
              stroke: #E67742;
            }
          }
        }
      }
    }
  }
}
