
.interviewNote{
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
        margin-bottom: 2rem;
         margin-top: 1rem; 
    } 
}
.noteArea{
    height: 15rem !important;
}
.buttonStyle{
    width: 25rem;
}

.modalBtnGrp{
display: flex;
justify-content: space-between;
}
.modalBtnStyle{
    width:50%
}