.form-container {
  &_form-input {
    vertical-align: middle;
    float: right;
  }

  input[id='title'] {
    text-transform: uppercase;
  }
}
.ant-form-horizontal .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ant-select-selection-item {
  display: block;
  height: 26px !important;
  span {
    padding: 2px 5px 2px 5px !important;
  }
  .ant-select-selection-item-remove {
    margin-left: auto;
  }
}
.ant-select-selection-placeholder {
  margin-left: 6px;
}

.form-card-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.form-card-container_sub-input {
  display: flex;
  justify-content: flex-start;
  width: 1150px !important;
}
.ant-form-item-label {
  text-align: left !important;
}
.ant-form-item-control {
  width: auto !important;
}
.ant-form-item-explain-error {
  /* left: 0; */
  /* margin-right: 5px; */
  margin-left: auto !important;
  margin-top: 15px !important;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: rgba(61, 164, 230, 0.3);
}
.ant-layout-header {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.08);
}
.ant-select-selection-overflow-item {
  width: 100%;
}

.disabled-row {
  background-color: #e5e5e5;
  pointer-events: none;
  &:hover {
    content: 'asd';
  }
}
.ant-pagination {
  align-items: center;
  .ant-pagination-options {
    .ant-pagination-options-size-changer {
      width: 120px;
    }
  }
}

// .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
//   padding-left: 60px !important;
//   width: 280px;
//   div {
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }
// }
input[name='teklifEdilenMaddeBasligi'] {
  text-transform: uppercase;
}

.ant-form-item-label {
  label[for='birinciEdisyondaYerAldi'],
  label[for='hangiMadde'],
  label[for='baskaIlimHeyetiKatkidaBulunmali'],
  label[for='yakinIfadeZikrediliyor'],
  label[for='yapilacakCalisma'] {
    height: auto;
    white-space: break-spaces;
    line-height: 23px;
  }
}
.ant-form-item-label {
  label {
    white-space: break-spaces;
    line-height: 23px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
}
.ant-steps-item-title {
  line-height: 20px;
}
.bottomArrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-top: 20px solid #e8e8e8;
  border-right: 10px solid transparent;
  position: static;
  border-radius: 2px;
  margin-top: -20px;
  margin-left: 20px;
}

.stickyForm {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.completed-step {
  .ant-steps-icon {
    color: #52c41a !important;
  }
  .ant-steps-item-tail::after {
    background-color: #52c41a !important;
  }
}
.process-step {
  .ant-steps-icon {
    color: #3da4e6 !important;
  }
}
.wait-step {
  .ant-steps-icon {
    color: #b8b8b8 !important;
  }
  .ant-steps-item-tail::after {
    background-color: #e8e8e8 !important;
  }
}
.accept-step {
  .ant-steps-icon {
    color: #62ca2f !important;
  }
  .ant-steps-item-tail::after {
    background-color: #62ca2f !important;
  }
}
.decline-step {
  .ant-steps-icon {
    color: #f5222d !important;
  }
  .ant-steps-item-tail::after {
    background-color: #e8e8e8 !important;
  }
}
.revent-step {
  .ant-steps-icon {
    color: #ff2e7e !important;
  }
  .ant-steps-item-tail::after {
    background-color: #e8e8e8 !important;
  }
}
.waiting-step {
  .ant-steps-icon {
    color: #3da4e6 !important;
  }
  .ant-steps-item-tail::after {
    background-color: #e8e8e8 !important;
  }
}
.change-committee-step {
  .ant-steps-icon {
    color: #e67742 !important;
  }
}
.pullback-step {
  .ant-steps-icon {
    color: #8D39E2 !important;
  }
  .ant-steps-item-tail::after {
    background-color: #8D39E2 !important;
  }
}
.offer-detail {
  .ant-descriptions-item-label {
    width: 45% !important;
    padding: 16px 12px !important;
  }
  .tiny-bar {
    width: 19% !important;
  }
  .ant-descriptions-item-content {
    padding: 16px 12px !important;
  }
}
@media screen and (max-width: 657px) {
  .offer-detail {
    .ant-descriptions-item-label {
      width: 0 !important;
    }
    .tiny-bar {
      width: 19% !important;
    }
    .ant-descriptions-item-content {
      padding: 16px 12px !important;
    }
  }
  .owner-user {
    .ant-descriptions-item-label {
      width: 0 !important;
    }
    .tiny-bar {
      width: 19% !important;
      padding: 16px 18px 16px 24px;
    }
    .ant-descriptions-item-content {
      padding: 16px 12px !important;
    }
  }
  .ant-steps-item-wait {
    display: none;
  }
  .ant-steps-item-finish {
    display: none;
  }
  .ant-steps-item-tail {
    display: none;
  }
  .ant-steps-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ant-steps-item-icon {
      margin-left: 0 !important;
    }
  }
}

.owner-user {
  .ant-descriptions-item-label {
    width: 31%;
    padding: 16px 12px !important;
  }
  .tiny-bar {
    width: 19% !important;
    padding: 16px 18px 16px 24px;
  }
  .ant-descriptions-item-content {
    padding: 16px 12px !important;
  }
}

.decision {
  .ant-descriptions-item-label {
    width: 23%;
    padding: 16px 12px !important;
  }
  .ant-descriptions-item-content {
    padding: 16px 12px !important;
  }
}

.order-descriptions {
  .ant-descriptions-item-label {
    width: 35%;
    padding: 16px 12px !important;
  }
  .ant-descriptions-item-content {
    padding: 16px 12px !important;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-bottom: auto !important;
  margin-top: 2px !important;
}

.clickable {
  cursor: pointer;
  content-visibility: auto;
  contain-intrinsic-size: 55px;
}

.hover {
  text-decoration: underline;
}
.offer-form-number:nth-child(2) {
  padding: 16px 10px !important;
}
.ant-input:hover {
  text-decoration: none !important;
}
.ant-space-item {
  width: 100% !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  text-decoration: none !important;
}
.ant-menu-title-content {
  overflow: initial !important;
}
.ant-form-item-control-input {
  min-height: 0 !important;
  cursor: default !important;
}
.ant-form-item-label {
  cursor: default;
}

.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}
.virtual-table-cell {
  box-sizing: border-box;

  border-bottom: 1px solid #e8e8e8;
  background: #fff;
}

.login-card {
  width: 500px;
  @media (min-width: 768px) and (max-width: 1920px) {
    width: 600px;
  }
  @media (min-width: 1921px) and (max-width: 2560px) {
    width: 700px;
  }
}
.active-filter-column {
  background-color: rgba(61, 164, 230, 0.2);
}

.ant-tabs {
  overflow: visible !important;
}
.ant-timeline.ant-timeline-alternate
  .ant-timeline-item-right
  .ant-timeline-item-content,
.ant-timeline.ant-timeline-right
  .ant-timeline-item-right
  .ant-timeline-item-content,
.ant-timeline.ant-timeline-label
  .ant-timeline-item-right
  .ant-timeline-item-content {
  width: calc(50% - 20px);
}

li.ant-menu-item {
  padding-left: 0 !important;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 15px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}
.ant-form-item-label > label {
  display: inline;
}
.rdw-editor-main{
  background-color:#fff;
  border:1px solid #ccc;
  padding: 16px;
max-height: 50vh;
}
.error-message {
  display: flex;
  justify-content: flex-end;
  color: #f5222d;
  margin: 20px 0 0 0;
  min-height: 20px;
  font-size: 14px;
  line-height: 1.3;
}

.ant-form-item-explain {
  margin-top: 5px;
  font-size: 14px;
}
